<!-- 招生系统基础配置 -- 中考普高控制线 -- 列表 -->
<template>
  <div>
    <list-template
        :loading="loading"
        :table-config="tableConfig"
        :table-data="tableData"
        :total="total"
        @onAdd="$router.push('./add')"
        has-add
        :current-page="page"
        @onChangePage="handleCurrentChange"
        @onHandle="handleEdit"
    ></list-template>
  </div>
</template>

<script>
//引入ajax接口管理层的接口函数
import {mapState} from "vuex";

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  data() {
    return {
      loading:true,
      tableData: [],
      //总条数
      total: 0,
      pageCount: 0,

      tableConfig: [
        {prop: "province_text", label: "高考省份"},
        {prop: "school_name", label: "报名学校"},
        {prop: "year", label: "高考年份"},
        {prop: "entry_year", label: "报名年份"},
        {
          prop: "handle",
          label: "操作",
          width: "180rem",
          handle: true,
          render(row) {
            return ["编辑"]
          }
        },
      ],
    }
  },
  created() {
    this.$store.commit("setPage", 1);
    this.getData()
  },
  activated() {
    this.getData()
  },
  computed: {
    ...mapState(["page"])
  },
  beforeRouteLeave(to, from, next) {
    next();
  },
  methods: {
    getData() {
      let params = {page: this.page};
      this.loading = true;
      this.$_register('api/recruit-v2/repeat-rank/get-list', {params}).then(res => {
        let data = res.data.data
        this.tableData = data.list
        this.total = data.page.total
      }).finally(()=>this.loading = false);
    },

    handleEdit(row, text) {
        this.$router.push('./edit?id=' + row.id)
    },

    // 当前页改变触发
    handleCurrentChange(val) {
      //当前页赋值给currentPage
      this.$store.commit("setPage", val);
      //拉取数据
      this.getData()
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .t-search-block .el-input {
  width: auto !important;
}

.role-list {
  padding-top: 24rem;
}

.button {
  margin-left: 34rem;
  margin-bottom: 30rem;
  box-sizing: border-box;
  width: 90rem;
  height: 34rem;
  opacity: 1;
  border-radius: 4rem;
}

.table {
  margin-left: 32rem;
}



::v-deep .el-table--enable-row-transition .el-table__body td.el-table__cell {
  text-align: left;
  font-size: 14rem;
  font-family: Microsoft YaHei;
  font-weight: normal;
  height: 48rem;
  box-sizing: border-box;
  color: #333333;
}

.header {
  position: fixed;
  top: 120rem;
  left: 300rem;
  font-size: 16rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 24rem;
  color: #333333;
  letter-spacing: 3.5rem;
}

.el-button--text {
  margin-right: 40rem;
  font-size: 14rem;
  font-family: Microsoft YaHei;
  font-weight: normal;
  color: #3491fa;
}
</style>
